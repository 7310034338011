import { ref } from 'vue';
import { useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';

export const useMeta = () => {
  const route = useRoute();

  const currentPage = ref({
    title: route.meta?.title || 'CareerBuddy: Connecting companies to high-quality remote talents',
    description:
      route.meta?.description ||
      'CareerBuddy is your trusted partner for companies looking to hire high-quality remote talents. Our platform connects fast growing companies to the most rigorously vetted pool of remote talents.',
    url: `${process.env.VUE_APP_BASE_URL}${route.fullPath}`,
  });

  return useHead({
    title: currentPage.value.title,
    meta: [
      {
        name: 'description',
        content: currentPage.value.description,
      },
      {
        property: 'og:title',
        content: currentPage.value.title,
      },
      {
        property: 'og:description',
        content: currentPage.value.description,
      },
      {
        property: 'og:url',
        content: currentPage.value.url,
      },
    ],
  });
};
