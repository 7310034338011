<template>
  <div class="page-load"></div>
  <Hero
    heroTitle="Put Your Brand right in front of the right people"
    heroDesc="If you are building a b2B or B2C company. You can’t reach the right people. Careerbudy’s media platforms are trusted by thousands of millennial and Gen-Z professionals across Africa."
    heroBtnText="Get Started"
    heroImg="hero5.png"
  />

  <Customers />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[65vw]">
      <div class="py-8">
        <br />
        <h2 class="mb-8 text-center text-[32px] md:text-[50px] font-medium">Our Clients</h2>
        <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16 rounded-lg mb-4">
          <img src="@/assets/images/zikoko.png" class="w-[70px] md:w-[150px] mx-auto" alt="" />
          <img src="@/assets/images/bolt.png" class="w-[70px] md:w-[98px] mx-auto" alt="" />
          <img src="@/assets/images/skillpaddy.png" class="w-[70px] md:w-[130px] mx-auto" alt="" />
          <img src="@/assets/images/earnipay.png" class="w-[70px] md:w-[150px] mx-auto" alt="" />
          <img src="@/assets/images/apt.png" class="w-[70px] md:w-[150px] mx-auto" alt="" />
          <img src="@/assets/images/yalo.png" class="w-[70px] md:w-[124px] mx-auto" alt="" />
          <img src="@/assets/images/mystash.png" class="w-[70px] md:w-[180px] mx-auto" alt="" />
          <img src="@/assets/images/altschool.png" class="w-[70px] md:w-[130px] mx-auto" alt="" />
          <img src="@/assets/images/ajocard.png" class="w-[70px] md:w-[200px] mx-auto" alt="" />
          <img src="@/assets/images/shuttlers.png" class="w-[70px] md:w-[174px] mx-auto" alt="" />
          <img src="@/assets/images/alx.png" class="w-[70px] md:w-[129px] mx-auto" alt="" />
          <img src="@/assets/images/zilla.png" class="w-[70px] md:w-[129px] mx-auto" alt="" />
          <img src="@/assets/images/pade.png" class="w-[70px] md:w-[157px] mx-auto" alt="" />
          <img src="@/assets/images/sla.png" class="w-[70px] md:w-[160px] mx-auto" alt="" />
          <img src="@/assets/images/techconnect.png" class="w-[70px] md:w-[180px] mx-auto" alt="" />
          <img src="@/assets/images/moniepoint.png" class="w-[70px] md:w-[150px] mx-auto" alt="" />
        </div>

        <br />
        <br />
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full lg:w-[83vw] px-[24px] md:px-6">
      <div class="py-[5rem]">
        <h2 class="text-center mb-8 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Our Media Brands</h2>
        <br />
        <br />

        <div class="block lg:flex gap-x-8 mb-16">
          <img src="@/assets/images/choice2.png" class="w-full lg:w-[500px] mb-8 lg:mb-0 rounded-3xl" alt="" />
          <div class="pt-6">
            <h1 class="text-[18px] md:text-[28px] leading-[110%] mb-4 font-semibold text-brand-black">CareerBuddy</h1>
            <p class="text-[14px] md:text-[21px] text-black/50">
              Careerbuddy’s digital community provides relatable & engaging content to a community of over 70k young professionals to help them grow
              and thrive in their careers. We curate content that offers our audience access to opportunities and resources to help them build a
              satisfying career and life.
            </p>

            <p class="my-4 font-bold text-[18px] md:text-[22px]">Properties</p>
            <div class="flex items-center flex-wrap">
              <a class="mr-4 mb-1 inline-block rounded-2xl px-6 py-2 border border-brand-black">Instagram</a>
              <a class="mr-4 mb-1 inline-block rounded-2xl px-6 py-2 border border-brand-black">Daily Newsletter</a>
              <a class="mb-1 inline-block rounded-2xl px-6 py-2 border border-brand-black">b2B Weekly Newsletter</a>
            </div>
          </div>
        </div>

        <div class="block lg:flex gap-x-8">
          <img src="@/assets/images/choice3.png" class="w-full lg:w-[500px] mb-8 lg:mb-0 rounded-3xl" alt="" />
          <div class="pt-6">
            <h1 class="text-[18px] md:text-[28px] leading-[110%] mb-4 font-semibold text-brand-black">WorkHERholic</h1>
            <p class="text-[14px] md:text-[21px] text-black/50">
              At WorkHerHolic, we drive conversation and inspire action for our community of African millenial and Gen-Z women who are daily crushing
              goals and looking for opportunities to connect, learn, and thrive! We are perfect for the African womanwho wants to get ahead in her
              career and have fun while at it.
            </p>

            <p class="my-4 font-bold text-[18px] md:text-[22px]">Properties</p>
            <div class="flex items-center flex-wrap">
              <a class="mr-4 mb-1 inline-block rounded-2xl px-6 py-2 border border-brand-black">Instagram</a>
              <a class="mr-4 mb-1 inline-block rounded-2xl px-6 py-2 border border-brand-black">Daily Newsletter</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw] flex items-center justify-center">
      <div class="py-[3rem] md:py-[8rem] text-brand-black text-center">
        <h1 class="text-brand-black text-[32px] md:text-[60px]">Ready to work together?</h1>

        <p class="text-[18px] md:text-[32px] leading-[110%] mb-[65px] text-brand-black/50">
          Partner with CareerBuddy today, and let’s craft a narrative that propels your <br class="hidden md:inline-block" />
          organization into the hearts and minds of Africa’s most coveted professionals
        </p>
        <br />
        <div>
          <a href="/contact" class="rounded-2xl px-8 md:px-16 py-4 text-center bg-brand-black text-white text-[15px] md:text-[18px]">Start Hiring</a>
        </div>
      </div>
    </div>
  </div>

  <CreateAccount />
</template>

<script setup>
import { onMounted } from 'vue';
import Hero from '@/components/partials/Hero.vue';
import CreateAccount from '@/components/partials/CreateAccount.vue';
import Customers from '@/components/partials/Customers.vue';
import { useMeta } from '@/utils/meta';

onMounted(() => {
  document.querySelector('.page-load')?.scrollIntoView();
});

useMeta();
</script>
